import {
  computed,
  inject,
  ref,
  unref,
} from 'vue';
import {
  pipe,
  subscribe,
} from 'wonka';
import EventEmitter from 'eventemitter3';
import USER_QUERY from './user.graphql';
import LOGIN_MUTATION from './mutations/login.graphql';
import IMPERSONATE_MUTATION from './mutations/impersonate_start.graphql';
import STOP_IMPERSONATE_MUTATION from './mutations/impersonate_stop.graphql';
import LOGOUT_MUTATION from './mutations/logout.graphql';
import RESET_MUTATION from './mutations/reset.graphql';
import CHANGE_EMAIL_MUTATION from './mutations/change_email.graphql';
import CHANGE_PASSWORD_MUTATION from './mutations/change_password.graphql';
import RESET_PASSWORD_MUTATION from './mutations/reset_password.graphql';
import { parseMutationResult } from '../utils/vuelidate.js';

class Session extends EventEmitter {
  constructor(client, resetClient) {
    super();
    this._client = client;
    this._resetClient = resetClient;
    this._unsubscribeHandle = null;
    this._ssr = import.meta.env.SSR;
    this.user = ref(undefined);
    // noinspection JSUnusedGlobalSymbols
    this.sessionInitialised = computed(
      () => this.user.value !== undefined,
    );
  }

  // noinspection JSUnusedGlobalSymbols
  install(app, injectKey) {
    app.provide(injectKey || 'session', this);
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$session = this;

    // noinspection JSIgnoredPromiseFromCall
    if (!this._ssr) {
      // noinspection JSIgnoredPromiseFromCall
      this.fetchUser();
    } else {
      this.user.value = null;
    }
  }

  _unsubscribe() {
    if (this._unsubscribeHandle) {
      this._unsubscribeHandle();
    }
    this._unsubscribeHandle = null;
  }

  fetchUser() {
    this._unsubscribe();
    return new Promise((resolve) => {
      const { unsubscribe } = pipe(
        this._client.value.query(USER_QUERY, {}, { requestPolicy: 'network-only' }),
        subscribe((result) => {
          // These updates are from urql's cache so: yes please.
          const login = result.data?.user?.id !== this.user.value?.id;
          this.user.value = result.data?.user;
          if (login) {
            this._resetClient();
            this.emit('login', this.user.value);
          }
          resolve();
        }),
      );
      this._unsubscribeHandle = unsubscribe;
    });
  }

  get login() {
    return async (username, password, next) => {
      const response = await this._client.value.mutation(
        LOGIN_MUTATION,
        {
          username, password, next,
        },
      ).toPromise();

      const { login } = parseMutationResult(response);
      if (login.__typename === 'User') {
        await this.fetchUser();
        return next;
      }

      return null;
    };
  }

  get impersonate() {
    return async (user) => {
      if (!this.canManageLicenses()) {
        return;
      }

      const response = await this._client.value.mutation(
        IMPERSONATE_MUTATION,
        {
          email: user.email,
        },
      ).toPromise();

      const { userImpersonate } = parseMutationResult(response);
      if (userImpersonate.__typename === 'User') {
        await this.fetchUser();
      }
    };
  }

  get logout() {
    return async () => {
      const response = await this._client.value.mutation(
        LOGOUT_MUTATION,
      ).toPromise();

      if (response.data.logout) {
        this._unsubscribe();
        this.user.value = null;
        this._resetClient();
        this.emit('login', null);
        return;
      }
      throw new Error();
    };
  }

  get stopImpersonate() {
    return async () => {
      const response = await this._client.value.mutation(
        STOP_IMPERSONATE_MUTATION,
      ).toPromise();

      const { userStopImpersonate } = parseMutationResult(response);
      if (userStopImpersonate.__typename === 'User') {
        await this.fetchUser();
      }
    };
  }

  get requestPasswordReset() {
    return async (email) => {
      const response = await this._client.value.mutation(
        RESET_MUTATION,
        { email: unref(email) },
      ).toPromise();

      if (!response.data.userPasswordRequestReset) {
        return;
      }
      throw new Error(response.data.userPasswordRequestReset.messages);
    };
  }

  get resetPassword() {
    return async (uid, token, password) => {
      const response = await this._client.value.mutation(
        RESET_PASSWORD_MUTATION,
        {
          uid: unref(uid),
          token: unref(token),
          newPassword1: unref(password),
          newPassword2: unref(password),
        },
      ).toPromise();

      if (response.data.userPasswordReset?.__typename === 'User') {
        return;
      }

      parseMutationResult(response);
    };
  }

  get changeEmail() {
    return async (email) => {
      const response = await this._client.value.mutation(
        CHANGE_EMAIL_MUTATION,
        { email: unref(email) },
      ).toPromise();

      parseMutationResult(response);
    };
  }

  get changePassword() {
    return async (oldPassword, newPassword) => {
      const response = await this._client.value.mutation(
        CHANGE_PASSWORD_MUTATION,
        {
          newPassword: unref(newPassword),
          oldPassword: unref(oldPassword),
        },
      ).toPromise();

      parseMutationResult(response);
    };
  }

  hasPermission(permissionRegex, strict = false) {
    const matcher = new RegExp(permissionRegex);
    return (strict === false && this.isSuperUser === true)
      || (!!this.user.value && !!this.user.value.permissions.find((p) => matcher.test(p)));
  }

  get isStaff() {
    return !!this.user.value?.isStaff;
  }

  get isSuperUser() {
    return this.hasPermission('admin:admin', true);
  }

}

export function createSession(client, resetClient) {
  return new Session(client, resetClient);
}

export default function useSession() {
  return inject('session');
}
